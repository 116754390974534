import React, { useState } from 'react';
import Cube from 'components/effects/cube';
import Sphere from 'components/effects/sphere';
import Torus from 'components/effects/torus';
import './styles.css';

const VideoPlayer = ({ videos }) => {
  const [videoSrc, setVideoSrc] = useState(0);
  const [verticalFlip, setVerticalFlip] = useState(false);
  const [horizontalFlip, setHorizontalFlip] = useState(false);

  const handleOnVideoEnd = () => {
    if(videoSrc < (videos?.length - 1)) setVideoSrc(videoSrc + 1)
    else setVideoSrc(0);
  };

  const renderPreview = () => {
    switch (videos[videoSrc].ffx) {
      case 'cube':
        return <Cube parentId="video-container" />
      case 'sphere':
        return <Sphere parentId="video-container"/>
      case 'torus':
        return <Torus parentId="video-container"/>
      default:
        return <Cube parentId="video-container"/>
    }
  };

  return(
    <div
      className="video-player-container"
      style={{
        transform: `scaleX(${horizontalFlip ? '-1' : '1'})`,
        transform: `scaleY(${verticalFlip ? '-1' : '1'})`
      }}
    >
      {videos?.length > 0 &&
        <div id="video-container" className="video-container">
          <video
            id="video"
            autoPlay
            muted
            src={`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_HOLOGRAM_VIDEO_DIR}${videos[videoSrc].filename}`}
            className="video-player-video"
            onEnded={handleOnVideoEnd}
            crossOrigin="anonymous"
          />
          {renderPreview()}
        </div>
      }
    </div>
  );
};

export default VideoPlayer;